import type { SVGProps } from "react";
const IconFlagVector = (props: SVGProps<SVGSVGElement>) => {
  const height = props.height || 21;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} fill="none" viewBox="0 0 9 21" {...props}>
      <path fill="currentColor" d="M7.826 0H0v21h7.953a1 1 0 0 0 .789-1.615L1.429 10l7.156-8.35A1 1 0 0 0 7.825 0" />
    </svg>
  );
};
export default IconFlagVector;
