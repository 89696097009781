import classNames from "classnames";
import Image from "next/image";
import { ComponentType, ReactNode, SVGProps } from "react";
import IconFlagVector from "../../atoms/Icons/FlagVector";

// Design files only indicate these as supported variants
const variants = {
  primary: {
    containerClassName: "bg-primary",
    iconClassName: "text-primary"
  },
  "primary-light": {
    containerClassName: "bg-primary-light",
    iconClassName: "text-primary-light"
  },
  secondary: {
    containerClassName: "bg-secondary",
    iconClassName: "text-secondary"
  },
  "secondary-light": {
    containerClassName: "bg-secondary-light",
    iconClassName: "text-secondary-light"
  },
  black: {
    containerClassName: "bg-black text-white",
    iconClassName: "text-black"
  }
} as const;

export type FlagVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as FlagVariant[];

export interface FlagProps {
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  variant?: FlagVariant;
  className?: string;
  containerClassName?: string;
  children?: ReactNode;
  showLeftFlag?: boolean;
  height?: number;
  iconImageUrl?: string;
}

export default function Flag({
  Icon,
  iconImageUrl,
  variant = "primary",
  showLeftFlag = true,
  className,
  containerClassName,
  height = 21,
  children
}: FlagProps) {
  const { containerClassName: variantContainerClassName, iconClassName } = variants[variant];
  return (
    <span className={classNames("inline-flex", className)} style={{ height: height }}>
      {!Icon && showLeftFlag && (
        <IconFlagVector height={21} width={9} className={classNames("scale-x-[-1] -mr-[1px]", iconClassName)} />
      )}
      <span
        className={classNames(
          "inline-flex py-1.5 px-[5px] items-center h-[21px] text-[13px] leading-[15px] italic rounded-sm",
          Icon && "rounded-l-sm",
          variantContainerClassName,
          containerClassName,
          iconImageUrl && "gap-1"
        )}
        style={{ height: height }}
      >
        {Icon && !iconImageUrl && <Icon />}
        {iconImageUrl && <Image src={iconImageUrl} alt="icon" width={200} height={200} className="size-[14px]" />}
        {children}
      </span>
      <IconFlagVector height={height} className={classNames(iconClassName, "-ml-[1px]")} />
    </span>
  );
}
