import Text from "@/components/v2/atoms/Text/Text";
import FlavorHero from "@/components/v2/molecules/FlavorHero/FlavorHero";
import MediaCarousel from "@/components/v2/organisms/MarketingCarousel/MarketingCarousel";
import BasicLayout from "@/components/v2/templates/BasicLayout/BasicLayout";
import { MarketingCarouselItemsDocument } from "@/generated/requests/services";
import classNames from "classnames";
import dayjs from "dayjs";
import { FrontPageDocument } from "generated/requests/backend";
import { Service, serverClient } from "lib/apollo";
import type { GetStaticPropsContext, InferGetStaticPropsType } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useEffect } from "react";
import { AppStoreURL, isChromeOnIOS, isIOS, onceEveryMinutes } from "static/lib/util";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function Index(props: Props) {
  const { t } = useTranslation();
  const { carouselItems, products, currentCookieWeek } = props;

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (!isIOS() || isChromeOnIOS()) {
      return;
    }
    if (onceEveryMinutes(20, "first_load")) {
      window.location.href = AppStoreURL;
    }
  });

  // cookie week starts/ends on Sunday, but we want to show Mon - Sat on the UI
  const startOfWeek = dayjs(currentCookieWeek?.startDate).add(1, "day");
  const endOfWeek = dayjs(currentCookieWeek?.endDate).subtract(1, "day");

  return (
    <>
      <div className="relative z-20">
        <MediaCarousel items={carouselItems} />
      </div>
      <div
        className={classNames(
          "flex flex-col py-6 px-4 gap-y-[30px] w-full max-w-8xl mx-auto overflow-x-clip",
          "md:py-[100px] md:px-[30px] md:gap-y-[50px]",
        )}
      >
        <div className="flex flex-col gap-y-4 lg:gap-y-[30px] mb-4">
          <div>
            <Text
              variant="body1"
              className="inline-block py-[3px] px-[18px] bg-primary rounded-[7.5px] md:!text-[21px] md:!leading-9"
            >
              Week of {startOfWeek.format("MMM D")} -{" "}
              {endOfWeek.isSame(startOfWeek, "month") ? endOfWeek.format("D") : endOfWeek.format("MMM D")}
            </Text>
          </div>
          <div>
            <Text
              as="h1"
              variant="display2"
              className="md:!text-[55px] md:!leading-[60px] lg:!text-[100px] lg:!leading-none capitalize"
            >
              {t("home:national_flavors")}
            </Text>
          </div>
        </div>
        {products.cookies.map((flavor, index) => (
          <FlavorHero key={flavor.id} {...flavor} reverse={index % 2 !== 0} />
        ))}
      </div>
    </>
  );
}

Index.getLayout = (page, props) => {
  return (
    <BasicLayout headerFade overrideOpenGraphVideo={props?.urls?.desktop} hideFullWidth>
      {page}
    </BasicLayout>
  );
};

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const [frontPage, marketingCarousel, translated] = await Promise.all([
    serverClient.query({
      query: FrontPageDocument,
      context: { service: Service.backend, locale },
    }),
    serverClient.query({
      query: MarketingCarouselItemsDocument,
      context: { service: Service.customer, locale },
    }),
    serverSideTranslations(locale, ["common", "home", "jobs", "catering", "order"]),
  ]);

  const desktop = frontPage.data?.desktop?.url || null;
  const mobile = frontPage.data?.mobile?.url || null;
  const cookies = frontPage.data?.cookies ?? [];
  const mystery = frontPage.data?.mystery ? [frontPage.data?.mystery] : [];
  const currentCookieWeek = frontPage.data?.currentCookieWeek;
  const carouselItems = marketingCarousel.data?.customerMarketing.marketingCarouselV2?.items || [];

  return {
    revalidate: 10 * 60,
    props: {
      carouselItems,
      urls: { desktop, mobile },
      products: {
        cookies: [...cookies, ...mystery],
      },
      currentCookieWeek,
      ...translated,
    },
  };
}
