import type { SVGProps } from "react";
const IconAngleRightSmallRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M10 16.75a.75.75 0 0 1-.53-1.281l3.47-3.47-3.47-3.47a.75.75 0 0 1 1.061-1.061l4 4a.75.75 0 0 1 0 1.061l-4 4a.75.75 0 0 1-.531.221"
    />
  </svg>
);
export default IconAngleRightSmallRegular;
