import { PublicCookieFlavor } from "@/generated/requests/backend";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { HTMLAttributes, useState } from "react";
import css from "styled-jsx/css";
import IconAngleRightSmallRegular from "../../atoms/Icons/AngleRightSmallRegular";
import Text from "../../atoms/Text/Text";
import Flag from "../Flag/Flag";

export interface FlavorHeroProps extends HTMLAttributes<HTMLElement>, PublicCookieFlavor {
  reverse?: boolean;
}

// NOTE: This is a one-off styling since we have dynamic colors and some nested
// elements.
const getFlavorStyles = (backgroundColor: string, onBackgroundColor: string) => {
  return css.resolve`
    @media (min-width: 1024px) {
      .group:hover .background,
      .group:focus .background,
      .group.highlighted .background {
        background-color: ${backgroundColor};
      }

      .group:hover .on-background,
      .group:focus .on-background,
      .group.highlighted .on-background {
        color: ${onBackgroundColor};
        border-color: ${onBackgroundColor};
      }

      .group:hover .on-background-link,
      .group:focus .on-background-link,
      .group.highlighted .on-background-link {
        background-color: ${onBackgroundColor};
      }

      .group:hover .on-background-link *,
      .group:focus .on-background-link *,
      .group.highlighted .on-background-link * {
        color: ${onBackgroundColor};
        filter: invert(1);
      }
    }
  `;
};

export default function FlavorHero({
  cookieId,
  newRecipeCallout,
  servingMethod,
  nameWithoutPartner,
  description,
  newAerialImage,
  featuredPartner,
  featuredPartnerLogo,
  backgroundColor = "#FCEBD9", // Butterscotch Cream
  onBackgroundColor = "#000", // Black
  reverse,
  className
}: FlavorHeroProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const [highlight, setHighlight] = useState(false);
  const { className: cssClassName, styles } = getFlavorStyles(backgroundColor, onBackgroundColor);

  return (
    <div
      id={cookieId}
      className={classNames(
        "group py-5 flex items-center min-h-[230px]",
        "sm:min-h-[380px] lg:min-h-[600px] md:py-20 xl:mx-0",
        reverse ? "-mr-28 md:-mr-[325px]" : "-ml-28 md:-ml-[325px]",
        highlight && "highlighted",
        className,
        cssClassName
      )}
    >
      <div
        onClick={() => router.push(`/profiles/${cookieId}`)}
        className={classNames(
          "hover:cursor-pointer",
          "background flex flex-grow justify-between p-5 rounded-2xl min-h-[165px]",
          "sm:min-h-[220px] md:p-[50px] md:rounded-[40px] lg:min-h-[374px]",
          "transition-colors duration-300 ease-in",
          reverse ? "flex-row-reverse" : "flex-row",
          cssClassName
        )}
      >
        <div
          className={classNames(
            "relative flex items-start flex-grow-0 flex-shrink-0 w-[260px]",
            "sm:w-[400px] md:w-[600px]",
            { "justify-start": reverse, "justify-end md:justify-center lg:justify-start": !reverse }
          )}
        >
          {featuredPartnerLogo && (
            <Image
              src={featuredPartnerLogo}
              width={500}
              height={500}
              alt={featuredPartner || t("home:featured_partner_logo")}
              className={classNames(
                "relative flex-grow-0 z-[1] mt-4",
                "lg:mt-0 md:mx-0 w-auto",
                reverse ? "ml-6" : "mr-6",
                "max-w-[130px] sm:max-w-[300px] md:max-w-[350px] max-h-[50px] sm:max-h-[100px] md:max-h-[125px]"
              )}
            />
          )}
          {newAerialImage && (
            <>
              <Image
                src={newAerialImage}
                width={1300}
                height={1300}
                alt={nameWithoutPartner || t("home:product_image")}
                className={classNames(
                  "absolute top-1/2 -translate-y-1/2 lg:size-[600px] hidden lg:block",
                  reverse ? "md:right-1/2 md:translate-x-1/2" : "md:left-1/2 md:-translate-x-1/2 ",
                  !reverse && "-scale-x-100"
                )}
              />
              <Image
                src={newAerialImage}
                width={520}
                height={520}
                alt={nameWithoutPartner || t("home:product_image")}
                className={classNames(
                  "absolute top-1/2 -translate-y-1/2 lg:size-[600px] lg:hidden",
                  reverse ? "md:right-1/2 md:translate-x-1/2" : "md:left-1/2 md:-translate-x-1/2 ",
                  !reverse && "-scale-x-100"
                )}
              />
            </>
          )}
        </div>
        <div className="flex flex-col flex-grow gap-y-[5px] md:gap-y-6">
          <div className="flex flex-col gap-y-[5px] md:gap-y-0">
            <div className="flex justify-start pb-[10px] md:pb-[15px] items-center">
              {newRecipeCallout && (
                <Flag className="pr-3" variant="primary-light" showLeftFlag={false}>
                  {t("home:new_flavor")}
                </Flag>
              )}
              <Text variant="body2" className={classNames(cssClassName, "hidden md:block on-background")}>
                {servingMethod}
              </Text>
            </div>
            <Text
              variant="display3"
              className={classNames(
                "on-background !text-[35px] md:!text-[45px] md:!leading-[60px] lg:!text-[70px] lg:!leading-none",
                "transition-colors duration-300 ease-in",
                cssClassName
              )}
            >
              {nameWithoutPartner.replace(" (synced)", "")}
            </Text>
            {featuredPartner && (
              <Text
                variant="title2"
                className={classNames(
                  "on-background md:!text-[50px] md:!leading-[60px]",
                  "transition-colors duration-300 ease-in",
                  cssClassName
                )}
              >
                {featuredPartner}
              </Text>
            )}
            {description && (
              <Text className={classNames("hidden on-background pt-[15px] md:block", cssClassName)}>{description}</Text>
            )}
          </div>

          {/* shown on mobile*/}
          <div className="flex gap-2 sm:gap-5">
            <Link
              href={`/profiles/${cookieId}`}
              onFocus={() => setHighlight(true)}
              onBlur={() => setHighlight(false)}
              className={classNames(
                "on-background",
                "md:hidden inline-flex items-center gap-x-1 h-[40px] md:h-[50px] rounded-full py-3",
                "transition-all duration-300 ease-in",
                "group-hover:lg:px-[25px] group-hover:lg:border lg:border-transparent",
                highlight && "px-[25px]",
                cssClassName
              )}
            >
              <Text className="pl-[5px] sm:!text-[13px] !text-[12px] md:!text-base md:!font-bold text-nowrap">
                {t("home:learn_more")}
              </Text>
              <IconAngleRightSmallRegular height={20} width={20} />
            </Link>
            {/* shown on tablet and desktop screens*/}
            <Link
              href={`/profiles/${cookieId}`}
              onFocus={() => setHighlight(true)}
              onBlur={() => setHighlight(false)}
              className={classNames(
                "on-background",
                "hidden md:inline-flex items-center h-[40px] md:h-[50px] rounded-full py-3",
                "transition-all duration-300 ease-in",
                "group-hover:lg:pl-[30px] pl-[0px] pr-[30px] group-hover:lg:border border-transparent",
                highlight && "lg:px-[30px]",
                cssClassName
              )}
            >
              <Text className="pl-[5px] sm:!text-[13px] !text-[12px] md:!text-base md:!font-bold text-nowrap">
                {t("home:learn_more")}
              </Text>
            </Link>
            <Link
              onClick={(e) => {
                e.stopPropagation(); // Prevent outer div from rerouting to profile
                router.push("/order");
              }}
              href={`/order`}
              onFocus={() => setHighlight(true)}
              onBlur={() => setHighlight(false)}
              className={classNames(
                "on-background-link",
                "hidden md:inline-flex items-center h-[40px] md:h-[50px] px-[30px] rounded-full py-3",
                "transition-all duration-300 ease-in",
                highlight && "lg:px-[30px]",
                cssClassName
              )}
            >
              <Text className={classNames("px-3 !text-[13px] md:!text-base md:!font-bold text-nowrap", cssClassName)}>
                {t("home:order_now")}
              </Text>
            </Link>
          </div>
        </div>
      </div>
      {styles}
    </div>
  );
}
