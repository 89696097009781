import type { SVGProps } from "react";
const IconPauseFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5 19.5v-15Q5 3 6.5 3h2Q10 3 10 4.5v15Q10 21 8.5 21h-2Q5 21 5 19.5M15.5 21h2q1.5 0 1.5-1.5v-15Q19 3 17.5 3h-2Q14 3 14 4.5v15q0 1.5 1.5 1.5"
    />
  </svg>
);
export default IconPauseFilled;
